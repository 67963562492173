.camera_main_div_cam_comp {
  position: fixed !important;
  top: 5%;
  left: 10%;
  height: 90vh !important;
  width: 80% !important;
  z-index: 4;
  padding: 2%;
  background-color: #ffffff;
  box-shadow: 0.1vw 0.1vw 0.5vw #9b9191;
  border-radius: 1vw;
  transform: scale(1);
  animation: camera 0.3s ease-out;
}
@keyframes camera {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.camera_holder_parent_div {
  width: 80%;
  height: 70%;
  margin-left: 10%;
  overflow: hidden;
  margin-top: 5%;
  border-radius: 1vw;
}
.camera_tag_div_apply_style {
  width: 100%;
}

.take_photo_camera_button_tag {
  width: 6vw;
  height: 6vw;
  bottom: 5%;
  padding: 1%;
  position: absolute;
  left: 47%;
  border-radius: 26vw;
  background: red;
  border: 0.3vw solid #ffffff;
  font-size: 1vw;
  color: #ffffff;
  font-weight: 700;
}

.camer_switch_icon_tag {
  height: 3vw;
  width: 3vw;
  position: absolute;
  right: 3%;
  bottom: 5%;
  background-color: rgba(240, 232, 232, 0.5);
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
  color: red; 
}


.close_button_camera_popup_tag{
  position: absolute;
  top: 2.4%;
  right: 2.4%;
  height: 1.5vw;
  width: 1.5vw;
  cursor: pointer;
  color: #807676;
}