.image_upload_file_from_computer{
    height: 1.2vw;
    width: 1vw;
}
.custom_file_upload{
    width: 100%;
    height: fit-content;
}
.upload_image_image_and_text_parent_div{
    height: fit-content;
    width: 98%;
    padding: 2%;
    cursor: pointer;
    border-top: 0.1vw solid rgba(217, 217, 217, 1);
   
}
.upload_image_image_and_text_parent_div span{
    margin-left: 3%;
    font-size: 0.9vw;
}