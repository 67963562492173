.login_main_div {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #87868679;
    margin: 0vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0%;
    left: 0%;
    z-index: 5;
  }
 
  .inside_box {
    position: relative;
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 2.2222222222222223vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
  }
  .cross_img {
    width: 2.5694444444444446vw;
    height: 2.5694444444444446vw;
    margin-left: 27.84722222222222vw;
    padding: 0.5791666666666667vw 0px 0px 0px;
  }
  .imayl_text {
    font-family: Inter;
    font-size: 2vw;
    font-weight: 600;
    line-height: 3.0256944444444445vw;
    text-align: center;
    color: #393939;
    margin: 0;
  }
  .Email_imayl {
    margin-top: 2.5111111111111112vw;
  }
  .login_user_input {
    width: 24.305555555555557vw;
    height: 4.027777777777778vw;
    border-radius: 0.9722222222222222vw;
    border: 0.06944444444444445vw solid black;
    padding-left: 1vw;
    font-family: Inter;
    outline: none;
    font-size: 1.2vw;
  }
  .login_user_input ::before{
    font-size: 1.2vw;
  }
  .login_user_input ::after{
    font-size: 1.2vw;
  }
  .login_user_input :focus{
    font-size: 1.2vw;
  }
  
  .password_imayl {
    margin-top: 1.1111111111111112vw;
    position: relative;
    display: flex;
    align-items: center;
  }
  .Continue_btn {
    width: 24.305555555555557vw;
    height: 4.027777777777778vw;
    border-radius: 0.9722222222222222vw;
    border: 0.06944444444444445vw;
    background: #07114B;
    color: #FFFFFF;
    margin-top: 1.1111111111111112vw;
    font-family: Inter;
    outline: none;
  }
  
  .password_eye_icon_imayl{
    height: fit-content;
    width: 1.5vw;
    position: absolute;
    right: 5%;
    transition: 0.3s ease-out;
  }
  
  .close_icon_imayl_login{
    width: 2vw;
    height: 2vw;
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
  }
  
  
  
  
  
  
  
  
  
  
  
  