@keyframes popupAnimation {
    0% {
        transform: scale(0.5);
        opacity: 0;
        left:17%;
        bottom: 1%;
    }
    100% {
        transform: scale(1);
        opacity: 1;
        left: 21%;
        bottom: 110%;
    }
}

.UploadDialogueBox_main_div {
    padding: 1%;
    height: fit-content;
    width: 25%;
    border-radius: 1vw;
    position: absolute;
    left: 21%;
    bottom: 110%;
    box-shadow: 0.06944444444444445vw 0.2777777777777778vw 0.8263888888888888vw 0vw hsla(0, 0%, 0%, 0.15);
    z-index: 1;
    background-color: #FFFFFF;
    border: 0.1vw solid #CFCFCF;
    animation: popupAnimation 0.3s ease-out forwards;
    z-index: 3 !important;
}


.image_size_uploaddialogue_box_tag{
    width: 1.5vw;
    height: 1.5vw;
}
.parent_div_connectionsparent_holder_div{
    height: fit-content;
    width:100%;
    padding: 2%;
    margin-top: 2%;
    cursor: pointer;
}
.image_size_uploaddialogue_box_text_tag{
    margin-left: 2%;
    font-size: 0.8vw;
}
