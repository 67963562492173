.text_table_data_component_main_div {
  height: 100vh;
  width: 63%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.table_data_component_main_div {
  width: fit-content;
  height: fit-content;
  animation: table 5s ease-in-out;
}

/* Container for table with custom scrollbar */
.table_parent_scroll_bar_main_div {
  width: 100%;
  overflow-x: scroll;
  position: relative;
  height: fit-content;
  border-left: 0.2vw solid #919191;
  border-right: 0.2vw solid #919191;
  border-top: 0.1vw solid #919191;
  border-bottom: 0.1vw solid #919191;
  border-radius: 0.8vw;
}

.table_main_div_table_data_holder {
  border: 0vw solid red !important;
}

.parent_div_image_chat_header_question_text_main_div {
  margin-top: 5%;
  height: fit-content;
  width: 100%;
  white-space: wrap;
  padding: 1%;
}

.parent_div_image_chat_header_question_text_main_div span {
  margin-left: 2%;
}

.table_cell_data_shipper_copilot {
  border: 0.0944444444444445vw solid #919191 !important;
  min-width: 150px;
  max-width: 300px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-left: 0.2%; */
  padding: 0.5% !important;
}

.table_head_div_tag {
  background-color: #d8d8d8;
}

.display_data_heading_with_shipper_logo {
  width: 100%;
}

.shipper_copilot_logo_inside_the_chat {
  height: 1.5vw;
  width: 1.5vw;
}

.text_inner_chat_heading {
  font-size: 0.9vw;
  font-weight: 500;
  margin-left: 2%;
  margin-top: 2%;
  width: 85%;
  height: fit-content;
  white-space: wrap;
}

.table_main_div_table_container {
  margin-top: 3%;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  width: 100%;
}

.image_tag_data_load_component_tag {
  height: 20vw;
  width: 15vw;
  margin-left: 65%;
  border-radius: 1.5vw;
  transform: scale(1);
  animation: chatimage 0.3s ease-out;
}

@keyframes chatimage {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.css-1ps6pg7-MuiPaper-root {
  border: 0.1vw solid black;
}

.image_parent_div_showing_data_component {
  width: 100%;
  position: relative;
  height: fit-content;
  margin-top: 5%;
}

.parent_image_loading_text_details_holder {
  font-size: 1vw;
  width: 50%;
  height: fit-content;
  white-space: wrap;
}

.parent_image_loading_text_details_holder span {
  margin-left: 5%;
}
.image_details_text_inside_chat {
  margin-left: 2%;
  margin-top: 0.9%;
  font-size: 1vw;
}
.image_details_text_div_parent_holers_logo_text {
  width: 70%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.postdata_refgerence_no_tag {
  color: blue;
  cursor: pointer;
}
.question_inside_chat_ui_parent_div {
  height: fit-content;
  width: 100%;
  margin-top: 7%;
}
.question_inside_chat_ui_parent_div span {
  padding: 1%;
  height: fit-content;
  width: fit-content;
  background-color: rgb(213, 225, 227);
  margin-right: 10%;
  max-width: 40%;
  display: inline-block; /* Ensures proper text wrapping */
  word-wrap: break-word; /* Allows long words to break */
  word-break: break-word; /* Ensures breaking of long, continuous words */
  border-radius: 1vw;
  font-size: 0.9vw;
  transform: scale(1);
  animation: questionask 0.5s ease-out;
}

@keyframes questionask {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.handle_iframe_parent_holder_in_chat {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 5%;
  height: fit-content;
  margin-top: 7%;
  padding: 2%;
  box-shadow: 0.5vw 0.5vw 1vw rgb(200, 196, 196);
  border-radius: 1vw;
  transform: scale(1);
  animation: iframe 0.7s ease-out;
  background-color: #ffffff;
}
@keyframes iframe {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.post_image_error_ocr_text {
  font-size: 1vw;
  margin-top: 1%;
  margin-left: 1%;
}

.download_table_download_icon_style {
  margin-top: 1%;
  margin-left: 2%;
  color: #888;
  cursor: pointer;
  height: 0.9vw;
  width: 0.9vw;
}
.download_excell_parent_holder {
  height: fit-content;
  width: 100%;
  position: sticky;
  left: 0%;
  padding: 0.6%;
}

@media only screen and (max-width: 900px) {
  /* .question_inside_chat_ui_parent_div span {
    padding: 3%;
    height: fit-content;
    width: fit-content;
    background-color: rgb(213, 225, 227);
    margin-right: 1%;
    max-width: 50%;
    display: inline-block;
    word-wrap: break-word;
    word-break: break-word;
    border-radius: 1vw;
    font-size: 3vw;
    transform: scale(1);
    animation: questionask 0.5s ease-out;
  } */
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  padding: 0.5%;
  left: -0.5%;
}

.sticky-id-header {
  background-color: #f0f0f0; /* Customize the background color for id */
  color: #000; /* Optional: Customize the text color for better contrast */
}

/* scroll bar code css */

.text_table_data_component_main_div {
  /* For hiding the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

  /* Hide scrollbar for WebKit-based browsers (Chrome, Safari, etc.) */
}

.text_table_data_component_main_div::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.table_parent_scroll_bar_main_div::-webkit-scrollbar {
  width: 0px;
  height: 1px;
}

/* Customize scrollbar track */
.table_parent_scroll_bar_main_div::-webkit-scrollbar-track {
  display: none !important;
}

/* Customize scrollbar thumb */
.table_parent_scroll_bar_main_div::-webkit-scrollbar-thumb {
  width: 2px;
  height: 2vw;
  position: absolute;
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Make the thumb corners rounded */
  border: 2px solid transparent; /* Add space around the thumb */
}

/* Hover state for the scrollbar thumb */
.table_parent_scroll_bar_main_div::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}
