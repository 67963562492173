.shipper_copilot_image_logo_tag_home {
    height: 4.875vw;
    width: 4.875vw;
    margin-top: 15%;
}

.logo_and_predetermined_prompts_holder_tag {
    width: 100%;
    margin-top: -2%;
}

.prompts_binding_holder_div {
    width: 98%;
    height: fit-content;
    margin-top: 3%;
    margin-left: -1%;
}

.prompt_box_main_div_home_page {
    width: 13.5%;
    height: fit-content;
    margin-left: 1%;
    border: 0.1vw solid #D5D5D5;
    border-radius: 1vw;
    padding: 1%;
    background-color: #F4F5F8;
    transition: 0.3s ease-out;
    color: #a7a7a7;
}

.prompt_box_main_div_home_page:hover {
    background-color: #D5D5D5;
    color: #4C4C4C;
    cursor: pointer;
}

.prompts_icons_images_tag_home {
    height: 2vw;
    width: 2vw;
}

.prompts_types_text_parent_div {
    width: 8.88888888888889vw;

    font-size: 1vw;
    font-weight: 500;
    margin-top: 5%;
}
@media only screen and (max-width: 500px) {
    .shipper_copilot_image_logo_tag_home {
        height: 11.875vw;
        width: 11.875vw;
        margin-top: 15%;
    }
}