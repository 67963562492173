* {
  font-family: "inter";
}

.shipper_copilot_home_main_div {
  height: 95%;
  background-color: #ffffff;
  width: 100%;
  animation: homeload 0.3s ease-out;
}

@keyframes homeload {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sub_main_div_home_shipper_copilot {
  position: absolute;
  right: 0;
  width: 82%;
  height: 95%;
  transition: 0.3s ease-in-out;
  background-color: #ffffff;
  overflow: hidden; /* Prevent overflow */
}

.shipper_co_pilto_text_headingh_home_div {
  width: 100%;
  height: 6.5%;
}

.shipper_co_pilto_text_headingh_home {
  color: #727272;
  font-size: 1.5667vw;
  margin-left: 2%;
  font-weight: 600;
  transition: 0.3s ease-out;
}

.shipper_copilot_chat_text_area_main_div_home {
  width: 98%;
  height: 89%;
  position: relative;
  overflow: hidden; /* Prevent overflow */
}

.shipper_copilot_chat_area_text_tag {
  width: 98%;
  padding: 2%;
  height: 98%;
  overflow: auto; /* Allow scrolling */
}

.chat_text_input_holder_div_tag_homepage {
  width: 80%;
  height: 100%;
}

.text_chat_display_div_parent {
  height: fit-content;
  width: 70%;
}

.text_chat_displya_div_parent_input_holder {
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
}

.input_fiels_shippercopiolet_chat_input_tag {
  height: 4vw;
  width: 60%;
  border-radius: 5vw;
  background-color: #f2f2f2;
  border: none;
  padding-left: 5%;
  padding-right: 5%;
  cursor: auto;
  font-weight: 500;
  font-size: 0.9722vw;
  color: #999999;
}

.input_fiels_shippercopiolet_chat_input_tag:focus {
  outline: none;
}

.input_fiels_shippercopiolet_chat_input_tag::placeholder {
  font-weight: 500;
  font-size: 0.9722vw;
  color: #999999;
}

.linkicon_hompage_input_icon {
  height: 3vw;
  width: 3vw;
  position: absolute;
  left: 21%;
  transform: scale(0.7);
  transition: 0.3s ease-out;
}

.linkicon_hompage_input_icon:hover {
  transform: scale(0.9);
  cursor: pointer;
}

.send_button_arrow_button {
  height: 3vw;
  width: 3vw;
  right: 20.5%;
  position: absolute;
  border-radius: 50%;
  border: none;
  background-color: #c5c5c5;
  transition: 0.3s ease-out;
}

.send_button_arrow_button:hover {
  background-color: rgb(27, 27, 85);
}

.uparrow_send_button_div {
  color: #ffffff;
  height: 90%;
  width: 40%;
  transition: 0.3s ease-out;
}

.shipper_copilot_chat_text_area_main_div_home_sub_div {
  width: 100%;
  height: 85%;
  overflow: auto; /* Allow scrolling */
  scroll-behavior: smooth;
}

.loading_text_tag_home {
  color: rgb(143, 131, 131);
  margin-left: -40%;
  font-size: 1vw;
}

@media only screen and (max-width: 500px) {
  .sub_main_div_home_shipper_copilot {
    width: 100%;
  }

  .shipper_co_pilto_text_headingh_home_div {
    height: 10%;
    width: 100%;
  }

  .shipper_co_pilto_text_headingh_home {
    font-size: 4vw;
  }

  .input_fiels_shippercopiolet_chat_input_tag {
    height: 15vw;
    width: 90%;
    font-size: 3vw;
  }

  .send_button_arrow_button {
    height: 10vw;
    width: 10vw;
    right: 7.5%;
  }
}
