.side_nav_bar_shipper_copilot_main_div {
  width: 18%;
  height: 100%;
  background-color: #f9f9f9;
}

.menue_toggle_ans_edit_main_div_holder {
  width: 100%;
  padding: 5%;
  height: 7%;
}

.toggle_image_side_navbar_tag {
  width: 1.3vw;
  height: 1vw;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.newchat_image_side_navbar_tag {
  height: 1.5vw;
  width: 1.5vw;
  margin-left: 83%;
  margin-top: -1%;
  z-index: 1;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.side_menue_text_shipper_copilot_types_div {
  height: fit-content;
  width: 100%;
}

.shipperlogoin_side_nav_bar-heading {
  height: 1.4vw;
  width: 1.4vw;
}

.side_menue_text_shipper_copilot_types_sub_div {
  width: 17vw;
  margin-top: 2%;
  padding: 3%;
  border-radius: 0.5vw;
  transition: 0.3s ease-out;
}
.side_menue_text_shipper_copilot_types_sub_div span {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
}

.BsThreeDots_sidenav {
  margin-top: 2%;
  margin-left: 10%;
  height: 1vw;
  width: 1vw;
}

.side_menue_text_shipper_copilot_types_sub_div:hover {
  background-color: rgba(216, 216, 216, 1);
  cursor: pointer;
}

.side_menue_text_shipper_copilot_types_sub_div span {
  margin-left: 4%;
  font-size: 1vw;
  font-weight: 400;
  color: rgba(114, 114, 114, 1);
}
.chats_side_nav_scroll_parent {
  width: 100%;
  height: 45%;
  overflow-y: scroll;
  text-align: left;
  margin-top: 5% !important;
}
.day_month_chats_tag {
  color: black !important;
  font-weight: 600 !important;
}

.catagory_tag_div_span {
  margin-left: 5%;
  font-size: 0.9vw;
  font-weight: 600;
}



.chats_side_nav_scroll_parent::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */ /* Set the height for horizontal scrollbars */
}

/* Customize scrollbar track */
.chats_side_nav_scroll_parent::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
  border-radius: 10px; /* Make the track corners rounded */
}

/* Customize scrollbar thumb */
.chats_side_nav_scroll_parent::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Make the thumb corners rounded */
  border: 2px solid transparent; /* Add space around the thumb */
}

/* Hover state for the scrollbar thumb */
.chats_side_nav_scroll_parent::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

.side_menue_text_shipper_copilot_types_sub_div {
  position: relative;
}

.BsThreeDots_sidenav {
  display: none;
}

.side_menue_text_shipper_copilot_types_sub_div:hover .BsThreeDots_sidenav {
  display: block;
}

.parent_chat_main_div_side_nav{
    position: relative !important;
}
.rename_delete_parent_div{
    position: absolute;
    padding: 0.5% 1.5% 0.5% 1.5%;
    background-color: #FFFFFF;
    left: 64%;
    top: 80%;
    border: 0.1vw solid #CFCFCF;
    box-shadow: 0.06944444444444445vw 0.0777777777777778vw 0.1263888888888888vw;
    z-index: 10;
    border-radius: 0.3vw;
}

.delete_chat_button_sidemenue{
   font-size: 0.8vw !important;
    border: none;
    cursor: pointer;
    margin-top: 3%;
    margin-left: 5%;
}
.delete_icon_sidemenue{
    height: 0.8vw;
    width: 0.6vw;
}
.rename_button_tag{
  font-size: 0.8vw;
  background-color: #FFFFFF;
  border: none;
}
.rename_input_tage_side_menue_chats{
  height: 1.5vw;
  width: 70%;
  font-size: 1vw;
  border: none;
}
.rename_input_tage_side_menue_chats:focus{
  outline: none;
}

@media only screen and (max-width: 500px) {
  .side_nav_bar_shipper_copilot_main_div {
    width: 50%;
    z-index: 5;
    /* display: none; */
  }
  .menue_toggle_ans_edit_main_div_holder {
    height: fit-content;
    padding: 7%;
  }
  .toggle_image_side_navbar_tag {
    width: 6vw;
    height: 5vw;
  }
  .newchat_image_side_navbar_tag {
    width: 6vw;
    height: 6vw;
  }

  .shipperlogoin_side_nav_bar-heading {
    height: 5.5vw;
    width: 5.5vw;
  }
  .side_menue_text_shipper_copilot_types_sub_div span {
    font-size: 4vw;
  }
  .catagory_tag_div_span {
    font-size: 3.5vw;
}
  .side_menue_text_shipper_copilot_types_sub_div{
    width: 45vw;
  }
}