.footer_main_div_shipper_copilot {
  height: 5%;
  width: 100%;
  background-color: #f1f1f1;
  font-size: 0.8vw;
  color: #637467;
}
@media only screen and (max-width: 900px) {
  .footer_main_div_shipper_copilot {
    font-size: 3vw;
  }
}
