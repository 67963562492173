.session_expired_parent_div {
    min-height: 100vh;
    width: 100%;
    position: relative;
  }
  .session_expired_main_logo {
    height: 3.8194444444444446vw;
    width: 3.8194444444444446vw;
  }
  .session_expired_image_1 {
    width: 100%;
    height: 100%;
    margin-left: 48.125vw;
    margin-top: 5.555555555555555vw;
  }
  .session_expired_child_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
  }
  .Session_expired_image {
    height: 6.319444444444445vw;
    width: 6.319444444444445vw;
  }
  .session_expired_text {
    margin-top: 1.6666666666666667vw;
    font-family: Inter;
    font-size: 1.6666666666666667vw;
    font-weight: 500;
    line-height: 2.017361111111111vw;
    text-align: center;
    color: #5B5B5B;
  }
  .session_expired_login_button {
    width: 28.88888888888889vw;
    height: 4.861111111111111vw;
    border-radius: 0.9722222222222222vw;
    margin-top: 3.0555555555555554vw;
    font-family: Inter;
    font-size: 1.3888888888888888vw;
    font-weight: 600;
    line-height: 1.6805555555555556vw;
    text-align: center;
    color: #FFFFFF;
    background: #000000;
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    .session_expired_main_logo {
      height: 10.8194444444444446vw;
      width: 10.8194444444444446vw;
    }
    .session_expired_image_1 {
      width: 100%;
      height: 100%;
      margin-left: 45.125vw;
      margin-top: 6.555555555555555vw;
    }
  .session_expired_child_div {
    margin-top: 28.22222222222222vw;
  }
    .Session_expired_image {
      height: 15.319444444444445vw;
      width: 15.319444444444445vw;
    }
    .session_expired_text {
      margin-top: 3.6666666666666667vw;
      font-size: 3.6666666666666667vw;
    }
    .session_expired_login_button {
      width: 57.88888888888889vw;
      height: 9.861111111111111vw;
      border-radius: 2.9722222222222222vw;
      font-size: 3.2888888888888888vw;
      margin-top: 4.0555555555555554vw;
    }
  }