* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.eship_jetai_parent_main_div {
    padding: 5.555555555555555vw 0vw 0vw 0vw;
}

.eship_jet_parent_child_div {
    display: flex;
    justify-content: center;
}

.eship_logo {
    width: 3.75vw;
    height: 3.75vw;
}

.eship_jetai_login_main_div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 13.402777777777779vw; */
    margin-top: 5vw;
}

.eship_login_title {
    font-family: "Inter";
    font-size: 2.5vw;
    font-weight: 600;
    text-align: center;
}

.ehsip_login_form_ {}

.form_div_login_eshipjet {
    display: flex;
    justify-content: center;
    padding: 2.3vw 0vw 1vw 0vw;
}

.eship_input {
    width: 24.3vw;
    height: 4.02vw;
    border-radius: 0.9722222222222222vw;
    color: #ffffff;
    margin: 0.6vw 0vw 0.6vw 0vw;
    font-family: "Inter";
    font-size: 1.3888888888888888vw;
    font-weight: 500;
    color: #919191;
    padding: 1.94vw;
    border: 1px solid #b0b0b0;
}

.forgot_password_div {
    padding-top: 0.6944444444444444vw;
}

.forgot_password_div span {
    font-family: "Inter";
    font-size: 1.1111111111111112vw;
    font-weight: 500;
    line-height: 19.36px;
    color: #07114b;
}

.form_eship_button_div {
    padding-top: 1.6666666666666667vw;
}

.form_eship_login_button {
    width: 24.3vw;
    height: 4.02vw;
    border-radius: 0.9722222222222222vw;
    background: #07114b;
    font-family: "Inter";
    font-size: 1.3888888888888888vw;
    font-weight: 600;
    color: #ffffff;
}

.eship_form_dont-haveaccount {
    display: flex;
    justify-content: center;
    margin-top: 1.111vw;
}

.dont-have_account_text {
    font-family: "Inter";
    font-size: 1.1111111111111112vw;
    font-weight: 500;
    text-align: center;
}

.singup_text_eship {
    font-family: "Inter";
    font-size: 1.1111111111111112vw;
    font-weight: 600;
    color: #07114b;
}

@media screen and (max-width: 767px) {
    .eship_jetai_parent_main_div {
        padding: 13vw 0vw 0vw 0vw;
    }

    .eship_logo {
        width: 10.555555555555555vw;
        height: 11.38888888888889vw;
    }

    .eship_jetai_login_main_div {
        margin-top: 30.833333333333336vw;
        /* background-color: red; */
    }

    .eship_login_title {
        font-family: "Inter";
        font-size: 6.666666666666667vw;
        font-weight: 600;
        text-align: center;
    }

    .eship_input {
        width: 88.88888888888889vw;
        height: 16.11vw;
        border-radius: 3.8vw;
        color: #ffffff;
        margin: 2.2vw 0vw 2.2vw 0vw;
        font-family: "Inter";
        font-size: 3.8vw;
        font-weight: 500;
        color: #919191;
        padding: 6.11vw;
        border: 1px solid #b0b0b0;
    }

    .forgot_password_div {
        padding-top: 1.6944444444444444vw;
    }

    .forgot_password_div span {
        font-family: "Inter";
        font-size: 3.333vw;
        font-weight: 500;
        color: #07114b;
    }

    .form_eship_button_div {
        padding-top: 6.6666666666666667vw;
    }

    .form_eship_login_button {
        width: 88.88888888888889vw;
        height: 16.11vw;
        border-radius: 3.8vw;
        background: #07114b;
        font-family: "Inter";
        font-size: 4.44vw;
    }

    .eship_form_dont-haveaccount {
        display: flex;
        justify-content: center;
        margin-top: 5.555vw;
    }

    .dont-have_account_text {
        font-family: "Inter";
        font-size: 3.888888888888889vw;
        font-weight: 500;
        text-align: center;
    }

    .singup_text_eship {
        font-family: "Inter";
        font-size: 3.888888888888889vw;
        font-weight: 600;
        color: #07114b;
    }
}